<template>
  <div class="search-filter">
    <div
      :class="{'search-filter__header' : true , 'search-filter__profile-page'  : color}"
    >
      <i class="icon icon-search" @click.stop=""></i>
      <input
        @keyup.enter.stop="handleEnterKey"
        class="nav-search"
        type="text"
        v-model="searchTopic"
        placeholder="Enter Search"
        />
      <i class="icon icon-add_plus" @click.stop="clearSearch()" v-if="clearSearchBtn"></i>
      <button  @click.stop="openSourcesDrawer()" class="btn" v-if="sourcesDrawerBtn">
        <img src="@/assets/icons/filter_alt.svg" width="auto" height="auto" alt="filter">
      </button>
      <!-- <div class="sort-container" @click.stop="openSortFilters()" v-if="sortBtn">
          <div class="sort">
              <img src="@/assets/icons/sort.svg" alt="icon" />
          </div>
          <div class="sort-dropdown" v-if="visibleSortFilter">
              <p @click="applySortFilter('recency')" :class="{ 'select-sort': selectSortDate }">Date</p>
              <p @click="applySortFilter('relevancy')" :class="{ 'select-sort': selectSortRelevance }">Relevance
              </p>
          </div>
      </div> -->
      <!-- <button @click="showSortDrawer()" class="btn sort-btn">
        <img src="@/assets/icons/sort.svg" alt="icon" />
      </button> -->
    </div>
    <div class="search-filter__body" v-if="serachDropdown">
      <div class="search-filter__body--articles">
        <h1 v-if="skeletonLoader ||  searchedQueryList.articles.length > 0" class="title">Articles</h1>
        <template v-if="!skeletonLoader && searchedQueryList.articles.length > 0" >
          <div class="article" v-for="(article) in searchedQueryList.articles" :key="article.id[0]" @click.stop="openArticle(article.id[0])">
            <div class="article__image">
              <img :src="article.image[0]" />
            </div>
            <div class="article__details">
              <h1>{{ article.title[0] === 'None' || !article.title[0] ? '' : article.title[0]}}</h1>
              <p> {{ article.authors[0] === 'None' || !article.authors[0] ? 'Author not identified' : `By ${article.authors[0]}` }}, {{ article.source[0] }} </p>
            </div>
          </div>
        </template>
        <div class="articles-skeleton" v-if="skeletonLoader">
          <a-skeleton
            v-for="(articles, index) in 4"
            :key="index"
            active
            :title="{ width: '4rem' }"
            :paragraph="{ rows: 2, width: ['100%', '60%'] }"
          />
        </div>
      </div>
      <div class="search-filter__body--users">
        <h1 v-if="skeletonLoader ||  searchedQueryList.users.length > 0" class="title">Networks</h1>
        <template v-if="!skeletonLoader && searchedQueryList.users.length > 0" >
          <div class="user" v-for="(user) in searchedQueryList.users" :key="user.id" @click.stop="redirectToUser(user)">
            <div class="user__image">
              <img :src="user.profile_picture" alt="" />
            </div>
            <div class="user__details">
              <h1>{{user.full_name}}</h1>
              <p>{{user.email}}</p>
            </div>
          </div>
        </template>
        <div class="users-skeleton" v-if="skeletonLoader">
          <a-skeleton
            v-for="(users, index) in 4"
            :key="index"
            active
            :title="{ width: '4rem' }"
            :paragraph="{ rows: 2, width: ['100%', '60%'] }"
          />
        </div>
      </div>
    </div>
  </div>

  <subscription-modal
    :visible="visibleSubscriptionModal"
    :visibleSignUp="visibleSubscriptionModal"
    @cancel="visibleSubscriptionModal = false"
    :footer="null"
  ></subscription-modal>

</template>

<script>
import SubscriptionModal from "../../components/Auth/AccessModal.vue";
import {mapActions, mapGetters} from 'vuex'
export default {
  components: {
    SubscriptionModal,
  },
  props: { engage: Boolean , color: Boolean },
  computed: {
    ...mapGetters({
      searchedQueryList: 'article/searchedQueryList'
    }),
    isAuthenticatedUser() {
      return this.$store.getters["auth/isAuthenticated"];
    },
    getSortFilter(){
      return this.$store.getters["discover/getSortFilter"];
    },
    newsArticleIncludeSources(){
      return this.$store.getters["discover/getIncludedSources"];
    },
    newsArticleExcludeSources(){
      return this.$store.getters["discover/getExcludedSources"];
    },
    newsArticleStartDate(){
      return this.$store.getters["discover/getStartDate"];
    },
    newsArticleEndDate(){
      return this.$store.getters["discover/getEndDate"];
    },
    insightsIncludeSources(){
      return this.$store.getters["discover/getInsightsIncludedSources"];
    },
    insightExcludeSources(){
      return this.$store.getters["discover/getInsightsExcludedSources"];
    },  
    insightsStartDate(){
      return this.$store.getters["discover/getInsightsStartDate"];
    },
    insightsEndDate(){
      return this.$store.getters["discover/getInsightsEndDate"];
    },
    // impactArticlesStartDate(){
    //   return this.$store.getters["impact/getImpactArticlesStartDate"];
    // },
    // impactArticlesEndDate(){
    //   return this.$store.getters["impact/getImpactArticlesEndDate"];
    // }
  },
  data() {
    return {
      searchTopic: "",
      clearSearchBtn: false,
      isSearched:false,
      visibleSubscriptionModal: false,
      visibleSortFilter : false,
      selectSortDate: true,
      selectSortRelevance: false,
      sortBtn: true,
      sourcesDrawerBtn: true,
      serachDropdown: false,
      skeletonLoader: false,
    }
  },
  mounted() {
    if(this.$route.name === 'classFolders') {
      this.sortBtn = false
      this.sourcesDrawerBtn = false
    }
  },
  watch: {
    searchTopic(val) {
      if(val === '') {
        this.clearSearchBtn = false
        this.emptySearchQueryList()
        this.clearSearch()
        this.serachDropdown = false
      } else {
        this.clearSearchBtn = true
        this.skeletonLoader = true
        this.serachDropdown = true
        this.debounce(async()=>{
          await this.searchQuery(val)
          this.skeletonLoader = false
        }, 2000)
      }
    },
    searchedQueryList(query) {
      if(Object.keys(query).length > 0) {
        if(query.articles.length === 0 && query.users.length === 0) {
          this.serachDropdown = false
        }
      } else {
        this.serachDropdown = false
      }
    }
  },
  methods: {
    ...mapActions({
      searchQuery: 'article/searchQuery',
      emptySearchQueryList: 'article/emptySearchQueryList',
      setActiveTabKey: 'profile/setActiveTabKey',
      setSelectedUser: 'profile/setSelectedUser'
    }),
    handleEnterKey() {
      this.serachDropdown = false
      this.emptySearchQueryList()
      const folderId = this.$route.path
      // The switch case statement check whether the path is discover or impact for searches.
      switch (this.$route.path) {
        case "/discover":
          this.searchArticle();
          this.isSearched = true // setting boolean to check if and only if the keyword has been searched.
          break;
        case folderId:
          this.searchFolderContent();
          this.isSearched = true; // setting boolean to check if and only if the keyword has been searched.
          break;
        case "/engage/impact":
          this.searchImpactArticle();
          this.isSearched = true // setting boolean to true if and only if the keyword has been searched.
          break;
        default:
          break;
      }
    },
    openSortFilters() {
      this.visibleSortFilter = !this.visibleSortFilter
    },
    async searchArticle() {
      this.$store.commit("discover/SET_INSIGHTS_PAGE_OFFSET", 0);
      this.$store.commit("discover/SET_ARTICLES_PAGE_OFFSET", 0);
      this.$store.commit("discover/SET_SEARCH_QUERY", this.searchTopic);
      this.searchNewsArticles();
      this.searchInsightsArticles();
    },
    async searchNewsArticles() {
      await this.$store.dispatch("discover/searchArticles");
    },
    async searchInsightsArticles() {
      await this.$store.dispatch("discover/searchInsights");
    },
    async searchImpactArticle(){
      if (this.isAuthenticatedUser) {
      this.emitter.emit("searching-impact-articles", true);
      const impactRequestObj = {
          query: this.searchTopic,
          offset: 0,
          // fromDate: this.impactArticlesStartDate,
          // toDate: this.impactArticlesEndDate,
          limit: 9,
          author: "",
      };
        await this.$store.dispatch("impact/fetchImpactArticle", impactRequestObj );
        this.emitter.emit("searching-impact-articles", false);
       } else {
        this.visibleSubscriptionModal = true;
      }
    },
    clearSearch() {
      this.searchTopic = ""
      // if a keyword has been searched and the input has been cleared afterwards
      if (this.isSearched)
        this.handleEnterKey()
      this.isSearched = false
    },
    openSourcesDrawer() {
      this.emitter.emit("show-sources-side-drawer");
    },
    applySortFilter(value) {
      this.$store.commit("discover/SET_SORT_FILTER", value);
      this.$store.commit("discover/SET_INSIGHTS_PAGE_OFFSET", 0);
      this.$store.commit("discover/SET_ARTICLES_PAGE_OFFSET", 0);
        this.searchNewsArticles();
        this.searchInsightsArticles();
        if (value === 'recency') {
            this.selectSortDate = true;
            this.selectSortRelevance = false;
        } else if (value === 'relevancy') {
            this.selectSortRelevance = true;
            this.selectSortDate = false;
        }
    },
    searchFolderContent() {
      try {
        const payload = {
          folderId: this.$route.params.id,
          searchQuery: this.searchTopic
        }
        this.$store.dispatch('folders/fetchFolderArticles', payload);
        this.$store.dispatch('folders/fetchFolderMedia', payload);
        this.$store.dispatch('folders/fetchFolderFiles', payload);
      } catch(err){
        console.err(err);
      }
    },
    debounce(method, timer) {
      if (this.$_debounceTimer !== null) {
        clearTimeout(this.$_debounceTimer);
      }
      this.$_debounceTimer = setTimeout(() => {
        method();
      }, timer);
    },
    redirectToUser(user) {
      this.setActiveTabKey('experience')
      this.setSelectedUser(user)
      this.$router.push(`/user/${user.username}/`);
      this.clearSearch()
      this.getUserInformation();
      this.serachDropdown = false;
    },
    async getUserInformation() {
      
      try {
        await this.$store.dispatch("profile/fetchUserProfile");
        await this.$store.dispatch("profile/loadExperiences");
        await this.$store.dispatch("profile/getAddedLanguages");
        await this.$store.dispatch("profile/fetchPhotoResume");
      } catch (err) {
        console.log(err);
      }
    },
    openArticle(id){
      this.clearSearchBtn = false
      this.emptySearchQueryList()
      this.clearSearch()
      this.serachDropdown = false
      this.$router.push(`/discover/article/Trending-news/${id}`);
      this.$store.commit("discover/SET_ARTICLE_READ_VIEW", true);
    }
  },
};
</script>


<style lang="scss">
.search-filter {
  width: 50rem;
  padding: 0;
  margin: 0 1rem;
  position: relative;
  margin-right: auto;
  @include respond(laptop-large) {
    width: 45%;
  }
  @include respond(tab-land) {
    width: 50%;
  }
  @include respond(laptop-small) {
    width: 60%;
  }
  @include respond(tab-port) {
    width: 65%;
  }
  &__header {
    background-color: $color-white;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    height: 4rem;
    margin: 0 auto;
    border-radius: 0.5rem;
    padding: 0 1.5rem;
    input {
      background-color: transparent;
      width: 100%;
      border: none;
      outline: none;
      height: 100%;
      font-size: 1.6rem;
      font-family: $font-primary;
      color: $color-black;
      padding: 0 1rem;
      @include respond(phone-x-small) {
        font-size: 1.6rem;
      }
    }

    input::placeholder {
      opacity: 0.6;
    }

    .icon {
      font-size: 1.4rem;
      color: $color-black;
      opacity: 0.6;
    }
    .icon-filter_list {
      font-size: 2.5rem;
      cursor: pointer;
    }
    .icon-add_plus {
      transform: rotate(45deg);
      cursor: pointer;
    }
    .btn {
      line-height: normal;
      border: none;
      outline: none;
      background-color: transparent;
      margin-left: 1.5rem;
      cursor: pointer;
      @include respond(phone-x-small) {
        display: none;
      }
      img {
        width: 1.8rem;
        height: auto;
      }
    }
    .sort-container {
      position: relative;
      @include respond(phone-x-small) {
        display: none;
      }
      .sort {
        line-height: normal;
        border: none;
        outline: none;
        background-color: transparent;
        margin-left: 1.5rem;
        cursor: pointer;
        img {
          width: 1.8rem;
        }
      }
      .sort-dropdown {
        position: absolute;
        width: auto;
        background-color: $color-white;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        border-radius: 5px;
        top: 3rem;
        right: 0;
        p {
          margin-bottom: 0;
          padding: 1rem 2.2rem !important;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 1.6rem;
          font-family: $font-primary-medium;
          line-height: normal;
          border-bottom: 1px solid #d6d6d6;
          cursor: pointer;
          border-start-start-radius: 5px;
          border-start-end-radius: 5px;
          &:last-child {
            border-bottom: none;
            border-end-start-radius: 5px;
            border-end-end-radius: 5px;
            border-start-start-radius: 0;
            border-start-end-radius: 0;
          }
        }
        .select-sort {
          background-color: $color-primary;
          color: $color-white;
        }
      }
    }
  }
  &__profile-page {
    background-color: #ececec;
    margin-right: 3rem;
    .btn {
      display: none;
    }
  }
  &__body {
    position: absolute;
    left: 0;
    width: 100%;
    background-color: $color-white;
    padding: 0;
    border: 1px solid $color-dark-grey-5;
    border-end-start-radius: 0.5rem;
    border-end-end-radius: 0.5rem;
    top: 3.8rem;
    max-height: 44.5rem;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      height: 0.4rem;
      width: 0.4rem;
    }

    &::-webkit-scrollbar-track {
      background-color: $color-white;
      border-radius: 0.8rem;
      height: 0.4rem;
      width: 0.4rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-primary;
      border-radius: 0.8rem;
    }
    &--articles,
    &--users {
      .article,
      .user {
        display: flex;
        align-items: center;
        padding: 1.5rem;
        transition: all .3s ease-in-out;
        cursor: pointer;
        &:hover {
          background-color: #ececec;
        }
        &__image {
          margin-right: 1rem;
          img {
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
            background-color: #ececec;
            object-fit: cover;
          }
        }
        &__details {
          h1 {
            font-size: 1.4rem;
            font-family: $font-primary-medium;
            margin-bottom: 0.5rem;
            color: $color-black;
            line-height: 1.6rem;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          p {
            font-size: 1.2rem;
            font-family: $font-primary-medium;
            margin-bottom: 0;
            color: $color-black;
            line-height: 1.4rem;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            opacity: 0.6;
          }
        }
      }
      .title {
        font-size: 1.6rem;
        line-height: 2rem;
        padding: 0 1.5rem;
        color: $color-black;
        font-family: $font-primary-medium;
        margin-bottom: 1rem;
        margin-top: 1rem;
      }
      .articles-skeleton,
      .users-skeleton {
        .ant-skeleton {
          .ant-skeleton-content {
            padding: 1.5rem;
            width: 100%;
            display: flex;
            align-items: center;
            .ant-skeleton-title {
              margin: 0 1rem 0 0;
              width: 4rem;
              height: 4rem;
              border-radius: 0.5rem !important;
            }
            .ant-skeleton-paragraph {
              width: calc(100% - 5rem);
              margin: 0;
              display: flex;
              flex-direction: column;
              gap: 0.8rem;
              li {
                margin: 0;
                height: 1.5rem;
              }
            }
          }
        }
      }
      .users-skeleton {
        .ant-skeleton {
          .ant-skeleton-content {
            .ant-skeleton-title {
              border-radius: 100% !important;
            }
          }
        }
      }
    }
    &--articles {
      .article {
        &__image {
          img {
            border-radius: 0.5rem;
          }
        }
      }
    } 
  }
}

</style>
