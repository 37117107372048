<template>
  <div class="profile-page-header-container">
  <div class="header">
    <div class="header__left">
        <span @click="redirectToDiscover()">
        <div class="logo-box">
          <img
            v-if="!whiteColor"
            src="../../assets/images/Amblem2@2x.webp"
            alt="logo"
            height="32"
          />
          <img
            v-else
            src="../../assets/images/logo@2x.webp"
            alt="logo"
            height="32"
          />
        </div>
      </span>
      <div v-if="showOnSubscription" class="navbar">
        <the-navbar :visibleOnInvite="visibleOnInvite" :whiteColor="whiteColor"></the-navbar>
      </div>
    </div>
    <div  v-if="!whiteColor" class="header__right">
      <navbar-search v-if="showOnSubscription" :color="true" :engage="false"></navbar-search>
      <!-- <a-tooltip class="link-tooltip">
        <template #title>Glocal AI</template>
        <img src="@/assets/icons/glocal-ai-icon-navbar-black.svg" alt="icon"
          v-if="showOnSubscription"
          @click="redirectToGlocalAI()"
          class="glocal-ai-icon"
          width="auto"
          height="auto"
        />
      </a-tooltip> -->
      <a-tooltip placement="bottom">
        <template #title>
            <h1>Requesting a new source?</h1>
            <p>In Glocal you can request more publication sources if you do not find them in our research source lists.</p>
        </template>
      </a-tooltip>
      <a-button
          v-if="showOnSubscription"
          class="invite-btn profile-page-btn"
          @click="visibleInviteToGlocalModal()"
      >
      <img src="@/assets/icons/forward_to_inbox.svg" alt="icon" class="contact-icon" width="auto" height="auto">
      Invite Contacts
      </a-button>
      <!-- <div class="search-box">
        <i class="search-icon icon icon-search"></i>
        <input type="text" class="search-bar" placeholder="Search" />
      </div> -->
      <!-- <router-link to="/workspace/write" class="btn btn-primary"
        >Post Article</router-link
      > -->
      <div class="header__right-navigation">
        <!-- <div v-if="intelligence" class="search">
          <div class="search-box">
            <i class="search-icon icon icon-search"></i>
            <input type="text" class="search-bar" placeholder="Search" />
          </div>
        </div> -->
        <!-- <i class="navigation-icon icon icon-message"></i>
        <i class="navigation-icon icon icon-bookmark"></i>
        <i class="navigation-icon icon icon-notification_bell"></i> -->
        <a-tooltip  class="link-tooltip">
          <template #title>Alerts</template>
          <div v-if="showOnSubscription" @click="showNotifications()" class="notification">
            <img src="@/assets/icons/notifications-icon-black.svg"
              alt="icon"
              class="navigation-icon bell-icon"
              width="auto"
              height="auto"
            />
            <span
              v-if="userRequestsCount || notificationsCount"
              class="notifications-alert"
            ></span>
          </div>
        </a-tooltip>
        <a-tooltip class="link-tooltip">
          <template #title>Archives</template>
          <img src="@/assets/icons/archivebox-black-icon.svg" 
            @click="redirectToArchive()"
            class="navigation-icon archive-icon" 
            width="auto"
            height="auto"
            alt="Archive"
          >
        </a-tooltip>
        <!-- <a-tooltip class="link-tooltip">
          <template #title>Portfolio</template>
          <img v-if="showOnSubscription" src="@/assets/icons/portfolio-black.svg" alt="icon"
            @click="openPortfolioDrawer()"
            class="navigation-icon"
          />
        </a-tooltip> -->
        <a-tooltip class="link-tooltip">
          <template #title>Network</template>
          <img src="@/assets/icons/diversity-icon-black.png" alt="icon"
            v-if="showOnSubscription"
            @click="openNetworks()"
            class="navigation-icon"
            width="auto"
            height="auto"
          />
        </a-tooltip>
        <a-tooltip class="link-tooltip profile-dropdown-tootip">
          <!-- <template #title>Other's</template> -->
          <a-dropdown :trigger="['click']" placement="bottomRight">
            <a class="ant-dropdown-link">
              <img
               :src="displayPicture"
               alt="user"
               class="navigation-icon icon-account"
               width="auto"
               height="auto"
              />
            </a>
            <template #overlay>
              <accounts-dropdown :showOnSubscription="showOnSubscription"></accounts-dropdown>
            </template>
          </a-dropdown>
        </a-tooltip>

      </div>
    </div>
    <div v-else class="header__right">
      <!-- <div v-if="intelligence" class="search-intelligence search-box">
        <i class="search-icon icon icon-search"></i>
        <input
          type="text"
          class="search-bar"
          placeholder="Wildfire in Turkey"
          v-model="searchIntelligence"
        />
        <i class="icon icon-filter_list"></i>
      </div> -->
      <slot name="right"> </slot>
    </div>
    <div class="mobile-navigation">
      <Transition name="slide-fade">
        <mobile-navbar v-if="show"></mobile-navbar>
      </Transition>
      <div
        class="mobile-navigation__hamburger"
        @click="mobileNavbar"
        :class="{ 'is-active': isActive }"
      >
        <span class="line"></span>
        <span class="line"></span>
        <span class="line"></span>
      </div>
    </div>
  </div>
  <!-- Mobile Search Bar -->
  <div class="mobile-search-bar">
    <navbar-search v-if="showOnSubscription" :color="true" :engage="false"></navbar-search>
  </div>
  </div>

  <notification-drawer
    :drawer-visibility="showNotificationDrawer"
    @close-drawer="showNotificationDrawer = false"
    :loading="loader"
  ></notification-drawer>
  <invite-to-glocal
      :visibleModal="inviteToGlocalModal"
      @closeModal="closeInviteToGlocalModal">
  </invite-to-glocal>
  <portfolio-drawer
    :drawer-visibility="showPortfolioDrawer"
    @close-drawer="closePortfolioDrawer()">
  </portfolio-drawer>

</template>
<script>
import theNavbar from "./ProfilePageNavbar.vue";
import AccountsDropdown from "../BaseComponents/AccountsDropdown.vue";
import MobileNavbar from "./MobileNavbar.vue";
import NotificationDrawer from "../PdbComponents/Notifications.vue";
import NavbarSearch from "../BaseComponents/NavbarSearch.vue";
import InviteToGlocal from "../BaseComponents/InviteToGlocal.vue";
import PortfolioDrawer from "../PdbComponents/PortfolioDrawer.vue";
import {mapGetters, mapActions} from 'vuex'


export default {
  props: {
    whiteColor: Boolean,
    intelligence: Boolean,
    showOnSubscription:{
      type: Boolean,
      default: true
    },
    visibleOnInvite: {
      type: Boolean,
      default: true
    }
  },
  components: {
    theNavbar,
    AccountsDropdown,
    NotificationDrawer,
    MobileNavbar,
    NavbarSearch,
    InviteToGlocal,
    PortfolioDrawer,
  },
  computed: {
    ...mapGetters({
      authUserInformation: 'profile/userInformation',
    }),
    userRequestsCount() {
      return this.$store.getters["profile/getUserRequestCount"];
    },
    notificationsCount() {
      return this.$store.getters["profile/getNotificationCount"];
    },
    userId(){
      return this.$store.getters["profile/currentLoginUser"]
    },
    displayPicture(){
      return this.$store.getters["profile/userInformation"].profile.profile_picture;
    },
  },
  data() {
    return {
      searchIntelligence: null,
      showNotificationDrawer: false,
      loader: true,
      show: false,
      isActive: false,
      inviteToGlocalModal: false,
      showPortfolioDrawer: false,
    };
  },
  methods: {
    ...mapActions({
      setActiveTabKey: 'profile/setActiveTabKey',
      setSelectedUser: 'profile/setSelectedUser'
    }),
    showNotifications() {
      this.getFeatures();
      this.showNotificationDrawer = true;
    },
    async getFeatures() {
      try {
        const response = await this.$store.dispatch(
          "article/getGlocalFeatures"
        );
        if (response.status === 200) {
          this.loader = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    mobileNavbar() {
      this.show = !this.show;
      this.isActive = !this.isActive;
    },
    redirectToTabs(val) {
      if (val === 'network' || val === 'portfolio') {
        this.$router.push({
          path: `/user/${this.userId}/`,
          query: { tab: val },
        });
      }
    },
    visibleInviteToGlocalModal() {
        this.inviteToGlocalModal = true
    },
    closeInviteToGlocalModal() {
      this.inviteToGlocalModal = false
    },
    redirectToGlocalAI() {
      this.$router.push('/glocal-AI');
    },
    openPortfolioDrawer() {
        this.showPortfolioDrawer = true;
    },
    closePortfolioDrawer(){
      this.showPortfolioDrawer = false;
    },
    openNetworks(){
      this.setActiveTabKey('network')
      this.setSelectedUser({...this.authUserInformation.profile, id:this.authUserInformation.id})
      this.$router.push({
        path: `/user/${this.authUserInformation?.profile?.username}/`,
      });
      this.getUserInformation()
    },
    redirectToArchive() {
      this.setActiveTabKey('archive')
      this.setSelectedUser({...this.authUserInformation.profile, id:this.authUserInformation.id})
      this.$router.push({
        path: `/user/${this.authUserInformation?.profile?.username}/`,
      });
      this.getUserInformation()
    },
    async getUserInformation() {
      try {
        await this.$store.dispatch("profile/fetchUserProfile");
        await this.$store.dispatch("profile/loadExperiences");
        await this.$store.dispatch("profile/getAddedLanguages");
        await this.$store.dispatch("profile/fetchPhotoResume");
        
      } catch (err) {
        console.log(err);
      }
    },
    redirectToDiscover() {
      this.$router.push({path:"/discover"})
    },
  },
};
</script>
<style lang="scss">
.profile-page-header-container {
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7.93rem;
  padding: 0 2rem !important;
  position: fixed;
  top: 0;
  z-index: 444;
  width: 100%;

  &__left {
    display: flex;
    align-items: center;
    .navbar {
      display: block;
      @include respond(laptop-small) {
        display: none;
      }
    }
    .logo-box {
      width: 3rem;
      height: auto;
      z-index: 222;
      cursor: pointer;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    .search-intelligence {
      margin-right: 3rem;
      z-index: 2;
      .icon-filter_list {
        font-size: 2rem;
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translate(0, -50%);
        color: $color-white;
      }
      @include respond(laptop-small) {
        margin-right: 1rem;
      }
    }
    // .glocal-ai-icon {
    //   width: 4rem;
    //   margin-right: auto;
    //   margin-left: 0.8rem;
    //   cursor: pointer;
    // }
    .header {
      &__right-navigation {
        display: flex;
        align-items: center;
        @include respond(laptop-small) {
          margin-left: 3rem;
        }
        .navigation-icon {
          color: $color-white;
          margin-left: 3.2rem;
          width: 2.4rem;
          height: auto;
          cursor: pointer;
          font-size: 3rem;
          @include respond(phone-large) {
            margin-left: 2rem;
          }
        }
        .notification {
          position: relative;
          margin-left: 3rem;
          width: 1.9rem;
          @include respond(phone-large) {
            margin-left: 2rem;
          }
          .bell-icon {
            width: 1.9rem;
            margin-left: 0;
          }
          .notifications-alert {
            position: absolute;
            content: "";
            width: 0.8rem;
            height: 0.8rem;
            background-color: red;
            border-radius: 100%;
            top: 0.2rem;
            right: 0.2rem;
          }
        }
        .profile-dropdown-tootip {
          margin-left: 3.4rem;
          @include respond(phone-large) {
            margin-left: 2rem;
          }
        }
        .icon-account {
          color: $color-black !important;
          border-radius: 100%;
          border: 0.2rem solid $color-black;
          object-fit: cover;
          font-size: 3rem;
          margin-left: 0;
          width: 5.2rem;
          background-color: #ebebeb;
        }
        .archive-icon {
          width: 3rem;
          height: 3rem;
        }
      }
    }

    .search-box {
      position: relative;
      @include respond(tab-port) {
        display: none;
      }
      .search-icon {
        font-size: 1.8rem;
        position: absolute;
        top: 50%;
        transform: translate(80%, -50%);
        color: $color-white;
      }

      input {
        background-color: #616271;
        border: none;
        height: 3.6rem;
        padding-left: 4.4rem;
        font-size: 1.44rem;
        color: $color-white;

        &:focus-within {
          outline: none;
        }
        &::placeholder {
          font-family: $font-secondary;
          color: #fff;
        }

        @include respond(laptop-small) {
          width: 19rem;
        }

        @include respond(phone-large) {
          width: 18rem;
        }
      }
    }

    .btn-primary {
      height: 3.6rem;
      background-color: $color-primary;
      color: $color-white;
      border: none;
      outline: none;
      padding: 1rem 5rem;
      font-family: $font-secondary;
      font-size: 1.44rem;
      line-height: 0px;
      margin-left: 1rem;

      @include respond(laptop-small) {
        padding: 1rem 3rem;
      }

      @include respond(tab-port) {
        display: none;
      }
    }

    &-navigation {
      .ant-dropdown-link {
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        width: max-content;
      }
    }
    .search-filter {
      display: block;
      @include respond(phone-x-small) {
        display: none;
      }
    }
  }
  .mobile-navigation {
    display: none;
    @include respond(laptop-small) {
      display: block;
    }
    .slide-fade-enter-active {
      transition: all 0.5s ease-out;
    }

    .slide-fade-leave-active {
      transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
      transform: translateX(-100px);
    }

    .slide-fade-enter-from,
    .slide-fade-leave-to {
      transform: translateX(100px);
      opacity: 0;
    }
    &__hamburger {
      margin-left: 3rem;
      margin-right: 0.5rem;
      cursor: pointer;
      .line {
        width: 20px;
        height: 2px;
        background-color: $color-white;
        display: block;
        margin: 4px auto;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }
    }
    &__hamburger.is-active .line:nth-child(2) {
      opacity: 0;
    }

    &__hamburger.is-active .line:nth-child(1) {
      -webkit-transform: translateY(7px) rotate(45deg);
      -ms-transform: translateY(7px) rotate(45deg);
      -o-transform: translateY(7px) rotate(45deg);
      transform: translateY(7px) rotate(45deg);
    }

    &__hamburger.is-active .line:nth-child(3) {
      -webkit-transform: translateY(-5px) rotate(-45deg);
      -ms-transform: translateY(-5px) rotate(-45deg);
      -o-transform: translateY(-5px) rotate(-45deg);
      transform: translateY(-5px) rotate(-45deg);
    }
  }
  .profile-page-btn {
    color: $color-white;
    font-family: $font-primary-medium;
    font-size: 1.6rem;
    background-color: $color-primary;
    padding: 0 2rem;
    height: 3.8rem;
    margin-left: 2rem;
    line-height: normal;
    border: none;
    outline: none;
    text-align: center;
    @include respond(laptop-small) {
      display: none;
    }

    span {
      line-height: 1.8rem;
    }
    .plus-icon {
      width: 1.4rem;
      height: auto;
      margin-right: 1.4rem;
      margin-bottom: 0.35rem;
    }
    .contact-icon {
      width: 2rem;
      height: auto;
      margin-right: 0.9rem;
    }
  }
}
.mobile-search-bar {
  display: none;
  position: sticky;
  top: 0;
  background-color: #e9e9e9;
  z-index: 44;
  padding: 1.5rem 4rem;
  @include respond(phone-x-small) {
    display: block;
  }
  .search-filter {
    width: 100%;
    margin: 0;
    margin-right: auto;
    &__profile-page {
      background-color: $color-white;
    }
  }
}
}

</style>
