<template>
  <a-modal
    :visible="visibleCollectionModal"
    :footer="null"
    @cancel="closeModal"
    wrapClassName="create-new-collaction-modal"
  >
    <div class="create-new-collaction">
      <h2 class="create-new-collaction__title">
        Create a new collection workspace
      </h2>
      <input
        v-model="folderName"
        type="text"
        placeholder="Add a name for your collection workspace."
        class="create-new-collaction__input"
      />
      <p v-if="error" class="create-new-collaction__error">This field is required</p>
      <a-button :loading="loader" @click="createNewFolder" class="create-new-collaction__btn">
        Create workspace
      </a-button>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    visibleCollectionModal: Boolean,
  },
  watch: {
    visibleCollectionModal(val){
      if(val){
        this.folderName =  "",
        this.error = false
      }
    }
  },
  data(){
    return {
      loader: false,
      folderName: "",
      error: false
    }
  },
  computed: { 
    artilcesFolders: {
      get(){
      return this.$store.getters["article/getArticlesFolders"];
      },
      set(val){
        this.$store.commit('article/setSaveArticleFolders', val)
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit("handle-close", false);
    },
    async createNewFolder() {
      try {
        if(this.folderName === ""){
          this.error = true;
          return false;
        }
        this.loader = true;
        const payload = {
          folder_name: this.folderName
        };
        const response = await this.$store.dispatch("article/saveArticleFolder", payload);
        if (response.status === 201) {
          this.artilcesFolders.push(response.data)
          this.error = false;
          this.folderName === "";
          this.closeModal();
          const message = {
            showMessageModal: true,
            title: "Workspace created successfully",
            type: "success",
          };
          this.emitter.emit('created-save-folder', message);
        }
        this.loader = false;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss">
@include reset-css;
.create-new-collaction-modal {
  .ant-modal {
    width: 46.6rem !important;
    .ant-modal-content {
      .ant-modal-body {
        .create-new-collaction {
          padding: 2.4rem 3rem;
          background-color: $color-light-grey-2;
          border-radius: 1rem;
          &__title {
            font-size: 2rem;
            font-family: $font-primary-medium;
            color: $color-black;
            margin-bottom: 0;
          }
          &__input {
            border: 1px solid $color-dark-grey-5;
            background-color: $color-white;
            font-size: 1.4rem;
            font-family: $font-primary;
            color: $color-black;
            height: 4rem;
            padding: 0 1rem;
            width: 100%;
            outline: none;
            margin: 2rem 0 0.4rem;
            &::placeholder {
              color: $color-black;
              opacity: 0.5;
            }
          }
          &__error {
            font-size: 1.4rem;
            font-family: $font-primary;
            color: red;
            margin-top: 0.4rem;
          }
          &__btn {
            background-color: $color-primary;
            color: $color-white;
            font-size: 1.6rem;
            font-family: $font-primary-medium;
            width: 13rem;
            height: 4rem;
            border: none;
            outline: none;
            display: block;
            margin-left: auto;
            margin-top: 2rem;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
